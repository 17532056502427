@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
.main-container {
  padding-right: 8px !important;
  padding-left: 8px !important; }

@media (max-width: 960px) {
  .main-container {
    padding-right: 0 !important;
    padding-left: 0 !important; } }

.made-changes-popup-description {
  width: 70%;
  margin-top: 10px; }
  @media (max-width: 960px) {
    .made-changes-popup-description {
      width: 100%; } }

.container .title {
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: left;
  margin: 0 8px;
  padding-left: 36px; }

.container .category {
  width: 100%;
  height: 46px;
  line-height: 46px;
  text-align: left;
  margin: 0 8px; }
  @media (max-width: 960px) {
    .container .category {
      height: 91px;
      line-height: 33px; } }
  .container .category a {
    color: #20303c;
    text-decoration: none;
    padding-left: 36px;
    display: block;
    font-size: 16px; }
    .container .category a svg {
      vertical-align: middle;
      margin-right: 15px; }
    @media (max-width: 960px) {
      .container .category a {
        font-size: 12px;
        font-weight: 700; } }
  .container .category:hover, .container .category.active {
    background-color: rgba(255, 255, 255, 0.5); }
    .container .category:hover a, .container .category.active a {
      color: #3899ec; }
  .container .category.active {
    background-color: #ffffff; }

.category-container {
  margin: auto;
  margin-top: 40px;
  height: auto;
  position: relative;
  max-width: 1400px; }
  @media (max-width: 1400px) {
    .category-container {
      max-width: 1260px; } }
  @media (max-width: 960px) {
    .category-container {
      position: absolute;
      margin-top: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch; }
      .category-container::-webkit-scrollbar {
        width: 0px; } }
  .category-container .mockup {
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    padding: 2px 4px; }
  .category-container .customize-link {
    z-index: 30;
    position: relative;
    text-align: center; }
    .category-container .customize-link button {
      font-weight: 700; }
  .category-container .sub-category-option.selected {
    color: #ffffff; }
  .category-container .sub-category-option .sub-category-option-title {
    font-size: 14px; }
  .category-container .sub-category-option .sub-category-option-desc {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px; }
  .category-container .options {
    width: 385px;
    margin: 0 55px;
    margin-left: 55px;
    position: relative; }
    .category-container .options .category-name {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      padding-bottom: 8px;
      white-space: pre-line; }
    .category-container .options .category-price-title {
      font-size: 24px;
      line-height: 40px;
      font-weight: 500; }
    .category-container .options .category-price-each {
      font-size: 12px;
      line-height: 24px;
      font-weight: 500; }
    .category-container .options .sub-category-desc {
      margin-top: 8px;
      margin-bottom: 8px;
      line-height: 18px;
      font-size: 14px;
      font-weight: 600; }
    .category-container .options .category-desc {
      margin-bottom: 30px;
      padding-bottom: 24px;
      border-bottom: 1px solid #EDEDED;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      white-space: pre; }
      @media (max-width: 1400px) {
        .category-container .options .category-desc {
          padding-bottom: 10px;
          margin-bottom: 10px; } }
      @media (max-width: 960px) {
        .category-container .options .category-desc {
          margin-bottom: 5px; } }
    .category-container .options .edit-business-info-link {
      margin-bottom: 28px;
      font-size: 14px;
      font-weight: 500;
      color: #3899ec; }
    .category-container .options .add-to-cart-btn {
      margin-top: 15px;
      bottom: 0;
      width: 100%; }
      @media (max-width: 1400px) {
        .category-container .options .add-to-cart-btn {
          margin-top: 5px; } }
      .category-container .options .add-to-cart-btn button {
        width: 100%; }
    .category-container .options .quantity-title {
      color: #32536a;
      margin-bottom: 6px; }
    .category-container .options .category-product-options {
      margin-bottom: 6px; }
    .category-container .options .category-product-options-box {
      margin-right: 15px;
      margin-bottom: 15px; }
    .category-container .options .category-product-qty-options {
      display: flex;
      padding-bottom: 30px; }
      .category-container .options .category-product-qty-options .category-product-options {
        padding-right: 10px; }
        .category-container .options .category-product-qty-options .category-product-options .title {
          padding-right: 10px; }
  .category-container .category-back-colors {
    margin-bottom: 15px;
    padding-bottom: 15px; }
    @media (max-width: 1400px) {
      .category-container .category-back-colors {
        margin-bottom: 5px;
        padding-bottom: 5px; } }
    .category-container .category-back-colors .category-back-colors-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 11px;
      color: #666666; }
  .category-container .conditions-box {
    color: #666666;
    padding-left: 23px;
    margin-top: 32px;
    font-size: 12px;
    font-weight: 500; }
    .category-container .conditions-box .conditions {
      font-weight: 500;
      line-height: 28px; }

.sub-category-options {
  width: 216px; }

hr.description-separator {
  box-sizing: border-box;
  border: 1px solid #EDEDED;
  margin-bottom: 24px; }

@media (min-width: 1400px) {
  .t_shirts-tick {
    width: 126px; } }

@media (max-width: 960px) {
  .conditions-box {
    left: 0px;
    margin-top: 55px;
    font-size: 12px;
    font-weight: 500; }
    .conditions-box .conditions {
      padding-bottom: 5px; }
  .category-container {
    padding-top: 0;
    margin-left: 0;
    margin-right: 0; }
    .category-container .mockup-wrapper {
      padding-left: 0;
      padding-right: 0;
      border-right: none;
      max-width: 100%;
      flex-basis: 100%; }
    .category-container .mockup {
      background: #f0f4f7; }
    .category-container .customize-link {
      margin-top: 0;
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      height: 65px; }
      .category-container .customize-link button {
        font-size: 16px;
        background-color: #ffffff;
        border-color: #ffffff;
        padding: 0 18px;
        height: auto;
        margin-top: 18px;
        color: #3899ec !important; }
        .category-container .customize-link button div {
          color: #3899ec !important; }
    .category-container .options {
      width: 100%;
      padding: 18px;
      position: relative;
      margin: 0; }
      .category-container .options .category-name {
        font-size: 24px;
        padding-bottom: 10px; }
      .category-container .options .category-price {
        font-size: 22px; }
      .category-container .options .category-price-each {
        line-height: 12px; }
      .category-container .options .add-to-cart-btn {
        display: block;
        margin-bottom: 30px;
        margin-left: 0;
        margin-top: 36px;
        width: 100%; }
        .category-container .options .add-to-cart-btn button {
          width: 100%; } }
      @media (max-width: 960px) and (max-width: 960px) {
        .category-container .options .add-to-cart-btn {
          margin-top: 5px; } }

@media (max-width: 960px) {
  .quantity input {
    height: 44px; }
  .quantity .quantity-ticker-wrapper {
    margin: 7px 9px; } }

.quantity-dropdown {
  display: flex;
  border-top: 1px solid #EDEDED;
  margin-top: 25px;
  padding-top: 25px; }
  @media (max-width: 1400px) {
    .quantity-dropdown {
      margin-top: 5px;
      padding-top: 10px; } }
  @media (max-width: 960px) {
    .quantity-dropdown {
      margin-top: 5px;
      padding-top: 10px; } }

.price-container {
  flex: 1;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  height: 40px; }
  @media (max-width: 960px) {
    .price-container {
      align-items: center; } }
  .price-container div {
    padding-right: 10px; }

.animation-1 {
  animation: 0.5s; }

.animation-2 {
  animation: 0.8s; }

.animation-3 {
  animation: 0.9s; }

.animation-4 {
  animation: 1s; }

.animation-5 {
  animation: 1.1s; }

.animation-6 {
  animation: 1.2s; }

.animation-7 {
  animation: 1.3s; }

.slide-from-right {
  animation-name: movement-1; }

@keyframes movement-1 {
  from {
    transform: translateX(200%); }
  to {
    transform: translateX(0); } }

.slide-from-top {
  animation-name: movement-2; }

@keyframes movement-2 {
  from {
    transform: translateY(-200%); }
  to {
    transform: translateY(0); } }

.discount-container {
  line-height: 24px !important; }

.price-before-discount {
  color: #CDCDCD;
  text-decoration: line-through;
  font-size: 18px;
  margin-right: 5px;
  display: inline-block; }

.discounted-price {
  color: #d01345;
  font-weight: 700; }

.discount-label {
  animation: 1s;
  animation-name: movement-3;
  text-align: left; }

@keyframes movement-3 {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(1440deg); } }
  @media (max-width: 960px) {
    .discount-label {
      text-align: right; } }
  .discount-label-inner {
    padding: 2px 5px;
    color: #ffffff;
    background-color: #d01345;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.5px; }

.sub-category-desc-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.hint {
  position: absolute;
  margin-left: 5px;
  text-align: center;
  background-color: #a9a9a9;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 11px;
  line-height: 14px;
  cursor: default; }
  .hint:before {
    content: '?';
    font-weight: bold;
    color: #ffffff; }

.discount-modal {
  background-image: url(/static/images/discount_bg.png);
  width: 500px;
  height: 350px;
  padding: 38px;
  color: #2C396C; }
  @media (max-width: 960px) {
    .discount-modal {
      width: auto;
      height: 350px;
      padding: 60px 10px; } }
  .discount-modal-text {
    margin: auto;
    width: 289px;
    font-size: 23px;
    line-height: 28px;
    text-align: center;
    font-weight: 500; }
    @media (max-width: 960px) {
      .discount-modal-text {
        width: auto; } }
  .discount-modal-bold {
    font-size: 52px;
    font-weight: bold;
    line-height: 70px; }
  .discount-modal-btn {
    background-color: #FFB64C;
    width: 167px;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-top: 15px; }

.shipping-line {
  font-weight: bold;
  height: 13px; }
