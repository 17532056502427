.vertical {
  display: flex;
  flex-direction: column; }

.horizontal {
  display: flex; }

.horizontal .radio-wrapper > label, :global(.ltr) .horizontal .radio-wrapper > label {
  margin-right: 20px; }

:global(.rtl) .horizontal .radio-wrapper > label {
  margin-left: 20px; }

.vertical .radio-wrapper {
  margin-bottom: 12px; }

.radio-wrapper {
  display: flex; }

.radio-wrapper, .radio-wrapper > * {
  cursor: pointer;
  box-sizing: border-box; }

.radio-wrapper > input {
  display: none; }

.radio-wrapper > label {
  display: flex;
  width: 100%; }

:global(.ltr) .radio-wrapper > label {
  flex-direction: row; }

:global(.rtl) .radio-wrapper > label {
  flex-direction: row-reverse; }

.radio-wrapper > label.vcenter {
  align-items: center; }

.radio-wrapper > label.vtop {
  align-items: top; }

.horizontal .children, :global(.ltr) .horizontal .children {
  padding-left: 6px; }

.children, :global(.ltr) .children {
  padding-left: 12px;
  width: 100%; }

:global(.rtl) .children {
  padding-right: 12px; }

.radio {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 50%;
  border-color: #55C5AD;
  color: #55C5AD; }

.radio.checked:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  background-color: currentColor;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  margin: 3px; }

.radio-wrapper:hover .radio {
  border-color: currentColor; }

.radio.disabled {
  border-color: #a3a3a3;
  background-color: #a3a3a3; }
