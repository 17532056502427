@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
.dropdown {
  padding-top: 25px; }
  .dropdown input:disabled {
    -webkit-opacity: 1;
    opacity: 1; }

.input-label {
  color: #666666;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  padding-bottom: 5px; }

.discount {
  text-decoration: line-through;
  opacity: 0.7;
  font-size: 90%;
  margin-right: 8px; }

.item-error {
  margin-bottom: 7px;
  font-size: 14px;
  line-height: 1.29;
  text-align: left;
  color: #ef5b53; }
  .item-error svg {
    left: 15px; }

.item-remove {
  position: absolute;
  cursor: pointer;
  z-index: 1; }

.demo-mode {
  position: fixed;
  top: 0;
  left: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  z-index: 9999999; }

.preview-mode-modal-title {
  font-size: 30px;
  line-height: 1.6;
  width: 380px;
  height: 96px;
  margin-top: -70px;
  margin-bottom: 11px; }

.i-agree-wrapper {
  display: flex;
  align-items: center; }

.order-summary {
  position: fixed;
  width: 470px;
  top: 78px;
  right: 10%;
  background: #fff;
  padding: 30px;
  border-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="10" viewBox="0 0 2 1" preserveAspectRatio="none"  fill="white"><polygon points="2 0 0 0 1 1"/></svg>') 0 0 100% repeat;
  border-width: 0 0 8px;
  border-style: solid;
  background-clip: padding-box;
  margin-bottom: -10px;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 5px 3px); }

.header {
  height: 125px;
  margin-bottom: 0; }

.header-left {
  height: 125px;
  line-height: 125px; }

.header-right {
  height: 125px;
  line-height: 125px; }

.page-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px; }

.continue-shopping {
  cursor: pointer; }

.header-line {
  background: rgba(18, 18, 18, 0.15);
  height: 1px;
  width: 100%;
  margin-bottom: 30px; }

.cart {
  background-color: #e5e5e5;
  padding: 25px 30px; }

.cart-title {
  font-size: 18px;
  text-align: left;
  width: 50%;
  display: inline-block; }

.edit-cart-btn {
  font-size: 14px;
  text-align: right;
  text-decoration: underline;
  width: 50%;
  display: inline-block;
  cursor: pointer; }

.edit-cart-btn:hover {
  color: #3899ec; }

.cart-items {
  margin-top: 16px; }

/* item */
.cart-row {
  padding-top: 18px;
  padding-bottom: 18px; }

.category .category-thumb {
  display: flex;
  justify-content: center;
  /* @include flex;
  @include flex-align-center; */
  position: relative;
  height: 60px; }

.category .category-thumb img {
  align-self: center;
  max-width: 100%;
  max-height: 100%; }

.category-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; }

.category-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  white-space: pre-line; }

.item-info {
  height: 100%;
  position: relative; }

.item-info .quantity {
  margin-top: 12px;
  font-size: 15px; }

.item-info .price {
  position: absolute;
  top: 0;
  right: 8px;
  font-size: 14px; }

.more-details {
  font-size: 12px;
  margin-top: 5px; }

/* summary */
.cart-summary {
  border-top: 1px solid #FFAF42;
  padding-top: 25px;
  margin-top: 10px;
  font-size: 14px; }

.total-line {
  font-size: 22px;
  font-weight: 600;
  margin-top: 48px; }

.summary-rows {
  margin-bottom: 20px !important; }

.summary-row {
  margin-bottom: 1px; }

.ssl_secure {
  max-width: 180px;
  margin-top: 25px; }

/* form */
.section {
  margin-top: 60px;
  margin-bottom: 60px;
  opacity: 0.5; }

.section.section-active {
  margin-top: 30px;
  margin-bottom: 30px;
  opacity: 1; }

.section-title-container {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  line-height: 42px; }

.section-title-text {
  padding-left: 15px; }

.section-number {
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  width: 42px;
  border: 2px solid;
  border-color: currentColor;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  text-align: center; }
  .section-number svg {
    height: 38px; }

.section-done {
  opacity: 1; }
  .section-done .section-title-container {
    color: #55C5AD; }

.edit-btn {
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: #FFAF42;
  font-weight: 600; }

.edit-btn:hover {
  color: #333333; }

.section-done-fields {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 15px; }
  .section-done-fields div {
    padding-top: 10px; }

.section-not-active .section-title {
  /*opacity: 0.6;*/ }

.section-not-active .section-body {
  position: relative;
  height: 1px;
  display: block;
  overflow: hidden;
  background: #d9dbdd;
  margin-top: 38px; }

.continue {
  margin-top: 50px;
  width: fit-content;
  height: 48px;
  border: none;
  font-size: 18px;
  text-align: center; }
  .continue button {
    padding: 0 50px; }

.shipping-options-box {
  border: 1px solid #d9dbdd;
  padding: 22px 30px;
  margin-top: 30px; }

.payment-options-box {
  border: 1px solid #d9dbdd;
  padding: 22px 30px; }

.payment-options-box:first-child {
  margin-top: 30px; }

.credit-card-form {
  padding: 30px;
  background-color: #f4f4f4;
  margin-top: 25px; }

.credit-card-title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 12px; }

.lock-icon {
  width: 13px;
  margin-right: 5px;
  padding-bottom: 5px; }

.hidden {
  display: none; }

.payment-form-field {
  height: 48px;
  background-color: #ffffff;
  border: solid 1px #d9dbdd;
  padding: 15px; }

.review-paragraph {
  font-size: 16px;
  margin: 16px 0; }

.input-col {
  margin-top: 25px; }

@media (max-width: 960px) {
  .checkout-wrapper {
    height: 100vh; }
  .header {
    height: 70px;
    margin-bottom: 0; }
  .header-left {
    height: 70px;
    line-height: 70px; }
    .header-left img {
      /*width: 45px;*/ }
  .header-right {
    height: 70px;
    line-height: 70px; }
  .page-title {
    padding-top: 32px; }
  .continue-shopping {
    /* display: none; */ }
  .header-line {
    background: rgba(18, 18, 18, 0.15);
    height: 1px;
    width: 100%;
    margin-bottom: 30px; }
  .cart {
    background-color: #f4f4f4;
    padding: 20px; }
  .cart-title {
    font-size: 18px;
    text-align: left;
    width: 50%;
    display: inline-block; }
  .edit-cart-btn {
    font-size: 14px;
    text-align: right;
    text-decoration: underline;
    width: 50%;
    display: inline-block;
    cursor: pointer; }
  .edit-cart-btn:hover {
    color: #3899ec; }
  .cart-items {
    margin-top: 16px; }
  /* item */
  .cart-row {
    padding-top: 18px;
    padding-bottom: 18px; }
  .category .category-thumb {
    /* @include flex;
    @include flex-align-center; */
    position: relative;
    height: 60px; }
  .category .category-thumb img {
    align-self: center;
    max-width: 100%;
    max-height: 100%; }
  .category-title {
    font-size: 13px; }
  .category-description {
    font-size: 12px; }
  .item-info .quantity {
    margin-top: 12px;
    font-size: 15px; }
  .item-info .price {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 14px;
    cursor: pointer; }
  .more-details {
    font-size: 12px;
    margin-top: 5px; }
  .total-line {
    margin-bottom: 20px; }
  .summary-rows {
    margin-bottom: 20px !important; }
  .summary-row {
    padding: 0; }
  .ssl_secure {
    max-width: 180px;
    margin-top: 25px;
    margin-bottom: 40px; }
  /* form */
  .section {
    margin-top: 40px;
    margin-bottom: 40px; }
  .section.section-active {
    margin-top: 30px;
    margin-bottom: 30px; }
  .section-title {
    font-size: 26px;
    line-height: 0.62;
    text-align: left; }
  .section-number {
    /* padding-right: 7px; */ }
  .section-done {
    vertical-align: middle;
    padding-right: 0; }
  .edit-btn {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: #FFAF42;
    font-weight: 600;
    padding-left: 40px; }
  .edit-btn:hover {
    color: #333333; }
  .section-done-fields {
    opacity: 0.6;
    font-size: 16px;
    line-height: 20px;
    margin-top: 15px; }
  .section-not-active .section-title {
    /*opacity: 0.6;*/ }
  .section-not-active .section-body {
    height: 1px;
    display: block;
    overflow: hidden;
    background: #d9dbdd;
    margin-top: 38px; }
  .continue {
    margin-top: 40px;
    margin-bottom: 48px;
    width: 100%;
    height: 48px;
    border: none;
    font-size: 18px;
    text-align: center; }
    .continue button {
      padding: 0; }
  .shipping-options-box {
    border: 1px solid #d9dbdd;
    padding: 22px 30px;
    margin-top: 30px; }
  .payment-options-box {
    border: 1px solid #d9dbdd;
    padding: 22px 30px; }
  .payment-options-box:first-child {
    margin-top: 30px; }
  .credit-card-form {
    padding: 30px;
    background-color: #f4f4f4;
    margin-top: 25px; }
  .credit-card-title {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 12px; }
  .lock-icon {
    width: 13px;
    margin-right: 5px;
    padding-bottom: 5px; }
  .hidden {
    display: none; }
  .payment-form-field {
    height: 48px;
    background-color: #ffffff;
    border: solid 1px #d9dbdd;
    padding: 15px; }
  .review-paragraph {
    font-size: 16px;
    margin: 16px 0; }
  .dropdown {
    padding-top: 25px; }
  .dropdown select {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    font-size: 16px; }
  .preview-mode-modal-title {
    font-size: 20px;
    line-height: 1.6;
    width: auto;
    height: 96px;
    margin-top: -50px;
    margin-bottom: 11px; }
  .order-summary {
    padding: 20px;
    padding-top: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 100px;
    position: relative;
    width: 100%;
    right: 0; }
  .input-col {
    margin-top: 18px; }
  .section-title-text {
    padding-left: 5px;
    font-size: 18px; } }

.required-star {
  color: #FFAF42;
  font-size: 18px;
  position: absolute;
  margin-left: 5px;
  margin-top: -3px; }

.disabled-country-tooltip {
  margin-top: 30px !important;
  background-color: #ffffff !important;
  color: #162D3D !important;
  max-width: 300px;
  -webkit-box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
  box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
  border-radius: 8px;
  padding: 12px 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.29;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased; }
  .disabled-country-tooltip:after {
    border-top-color: #ffffff !important; }

.back-color-wrap {
  color: #9CA1AD;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  margin-top: 10px;
  display: flex;
  justify-content: left;
  align-items: center; }

.back-color-rect {
  height: 30px;
  width: 40px;
  margin-right: 10px;
  border: 0.5px solid #9CA1AD; }

.creation-preview {
  /* width: 92px;
  border: 0.5px solid #9CA1AD; */ }
