.color-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px; }
  .color-circle svg {
    color: #ffffff; }

.white {
  border: 1px solid #EDEDED; }

.white.selected svg, .light_grey.selected svg {
  color: rgba(51, 51, 51, 0.5); }

.color-selection-container {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 25px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  padding: 8px 12px; }
