@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
.terms-btn {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  vertical-align: baseline; }

.terms-modal-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px; }
  @media (max-width: 960px) {
    .terms-modal-close-btn {
      display: none; } }

.terms-footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center; }

.terms-modal-btn {
  width: fit-content;
  padding: 0 75px; }

.i-agree-wrapper {
  display: flex;
  align-items: center; }

.checkout-wrapper {
  /*height: 100vh;*/
  padding-top: 32px;
  margin-bottom: 50px; }
  .checkout-wrapper :global .container {
    max-width: 990px !important; }

.sections-wrapper {
  margin-top: 25px; }

.review-paragraph {
  font-size: 16px;
  margin: 24px 0; }

.input-col {
  margin-top: 25px; }

.shipping-options-box {
  font-size: 16px;
  font-weight: 500;
  padding-top: 25px; }

.shipping-option {
  display: flex;
  justify-content: space-between; }

.shipping-description {
  opacity: 0.6;
  font-size: 90%; }

.shipping-price {
  margin-left: 40px;
  font-size: 16px;
  font-weight: 600; }

@media (max-width: 960px) {
  .checkout-wrapper {
    height: 100vh;
    padding-top: 0px; }
  .review-paragraph {
    font-size: 16px;
    margin: 16px 0; } }
