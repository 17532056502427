.root {
  margin-top: 25px; }

.input-label {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding-bottom: 5px; }

.input-field {
  width: 100%;
  height: 40px;
  border: solid 1px rgba(18, 18, 18, 0.15);
  border-radius: 3px;
  padding: 10px;
  font-size: 16px; }

.input-field::placeholder {
  font-size: 16px;
  opacity: 0.5; }

.input-field.error {
  border: solid 1px #fb4b4b; }

.error-message {
  color: #fb4b4b; }
