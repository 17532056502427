@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
.modal {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }
  .modal div {
    box-sizing: border-box; }

.portal :global .ReactModal__Content {
  opacity: 0.66;
  transform: scale(0.93);
  height: 80vh;
  width: 60vw;
  background-color: #fff; }
  @media (max-width: 960px) {
    .portal :global .ReactModal__Content {
      transform: scale(1.2);
      margin-top: auto !important;
      margin-bottom: auto !important;
      height: 90vh;
      width: 100vw; } }
  .portal :global .ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform 0.35s cubic-bezier(0.23, 1, 0.32, 1); }
  .portal :global .ReactModal__Content--before-close {
    pointer-events: none;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.04s cubic-bezier(0.23, 1, 0.32, 1); }
  .portal :global .ReactModal__Content:focus {
    outline: none; }

.portal :global .ReactModal__Overlay {
  background-color: rgba(51, 51, 51, 0.7) !important;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999; }
  @media (max-width: 960px) {
    .portal :global .ReactModal__Overlay {
      overflow-y: auto;
      padding: 18px; } }
  .portal :global .ReactModal__Overlay--after-open {
    background: rgba(22, 45, 61, 0.66); }
  .portal :global .ReactModal__Overlay--before-close {
    pointer-events: none;
    background: rgba(22, 45, 61, 0); }

.portal-non-scrollable :global .ReactModal__Overlay {
  overflow: hidden; }
