.header {
  margin: 20px 0; }
  .header .title {
    font-size: 32px;
    color: #ffffff;
    padding-top: 12px;
    text-align: center;
    line-height: 40px;
    font-weight: 600; }
  .header .message {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    margin-top: 16px; }
  .header .continue-shopping-btn {
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    margin-top: 45px;
    text-decoration: underline;
    cursor: pointer; }

.order-number {
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  padding-bottom: 14px; }

.information {
  border-bottom: 1px solid #ededed;
  padding-top: 32px;
  padding-bottom: 32px; }
  .information .title {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 14px;
    line-height: 25px; }
  .information .body {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6; }

.items-list .item {
  padding: 20px 0;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6; }
  .items-list .item .image img {
    max-width: 100%; }
  .items-list .item .description {
    /* padding-top: 14px; */
    /* opacity: 0.5; */ }
  .items-list .item .price {
    text-align: right; }

.summary {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed; }
  .summary .row {
    padding-bottom: 5px; }
    .summary .row .price {
      float: right; }
    .summary .row .total {
      padding-top: 20px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.45;
      border-top: 1px solid #ededed; }

.continue-shopping-btn {
  font-size: 15px;
  font-weight: 300;
  margin: 50px 0;
  cursor: pointer; }

@media screen and (max-width: 768px) {
  .header {
    margin: 40px 0 30px 0; }
    .header .title {
      font-size: 30px;
      line-height: 30px; }
    .header .message {
      font-size: 15px;
      line-height: 15px;
      margin-top: 13px; }
    .header .continue-shopping-btn {
      font-size: 15px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 2.13; }
  .order-number {
    font-size: 20px;
    margin-bottom: 16px; }
  .information {
    padding: 20px 0; }
    .information .title {
      font-size: 20px;
      font-weight: 300;
      padding-bottom: 6px;
      line-height: 1.6; }
  .items-list .item {
    padding: 20px 0;
    font-size: 15px;
    position: relative; }
    .items-list .item .description {
      padding-top: 0; }
    .items-list .item .price {
      position: absolute;
      top: 20px;
      right: 0; }
  .summary {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.6;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: none; } }
