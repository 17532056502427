@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
.color-box {
  display: inline-block;
  position: relative;
  text-align: center;
  margin-right: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #c8e7ff; }
  .color-box .inner {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px rgba(43, 129, 203, 0.24);
    margin: auto;
    margin-top: 4px; }

.color-box-selected {
  border: solid 2px #459fed; }
  .color-box-selected .inner {
    margin-top: 3px; }
  .color-box-selected .v-mark {
    width: 20px;
    height: 20px;
    background-color: #459fed;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    right: -8px; }
    .color-box-selected .v-mark svg {
      vertical-align: middle;
      color: #fff;
      width: 10px;
      height: 8.4px; }

.white {
  background-color: #ffffff; }

.black {
  background-color: #000000; }

.title {
  color: #666666;
  margin-bottom: 8px;
  padding-right: 16px; }

.dropdown {
  display: flex;
  margin-bottom: 18px; }

.color-title {
  color: #32536a; }

.sub-category-option .sub-category-option-title {
  font-size: 14px; }

.sub-category-option .sub-category-option-desc {
  border-radius: 19.5px;
  border: 1px solid #FFAF42;
  padding: 11px;
  height: 40px; }

@media (max-width: 960px) {
  .options .dropdown .title {
    font-size: 14px; }
  .sub-category-option {
    padding: 0;
    height: auto; } }

.vertical {
  line-height: 40px; }

.options-picker {
  display: flex;
  margin-bottom: 15px; }

.option {
  border-radius: 19.5px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  padding: 11px;
  height: 40px;
  margin-right: 15px; }
  .option.selected {
    border-color: #FFAF42; }
  @media (max-width: 960px) {
    .option {
      margin-right: 5px; } }
