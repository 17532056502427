.select-shipping-mobile {
  float: right;
  display: inline-block;
  position: absolute;
  right: 0;
  width: 60px; }
  .select-shipping-mobile select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
    cursor: default;
    border: none;
    background-color: transparent;
    display: inline-block;
    float: right;
    width: 60px; }

.root {
  position: relative;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.2); }
  .root select {
    -webkit-appearance: none;
    -webkit-border-radius: 19.5px; }

.control {
  color: rgba(0, 0, 0, 0.8);
  position: relative;
  overflow: hidden;
  border-radius: 19.5px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  font-size: 15px;
  box-sizing: border-box;
  outline: none;
  padding: 0 35px 0 16px;
  transition: all 200ms ease;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  min-width: 80px; }

.square {
  border-radius: 3px;
  -webkit-border-radius: 3px !important; }

.control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); }

.arrow {
  position: absolute;
  color: #333333;
  right: 0;
  top: 0;
  padding: 0 15px;
  line-height: 40px;
  pointer-events: none; }

.menu {
  background-color: #FAFAFA;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch; }

.shipping-drop-down {
  position: absolute;
  right: 0;
  top: 20px;
  box-sizing: border-box;
  max-height: 340px;
  width: 310px;
  z-index: 1000; }

.menu-shipping {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid;
  box-sizing: border-box;
  margin-top: 13px;
  max-height: 340px;
  overflow-y: auto;
  position: relative;
  top: 100%;
  right: 0;
  width: 310px;
  z-index: 1000;
  -webkit-overflow-scrolling: touch; }

.shipping-arrow {
  position: absolute;
  height: 13px;
  width: 13px;
  right: 20px;
  top: 7px;
  z-index: 1001;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top: 1px solid;
  border-left: 1px solid; }

.shipping-change {
  margin-left: 10px;
  cursor: pointer; }

.menu .group > .title {
  padding: 8px 10px;
  font-weight: bold;
  text-transform: capitalize; }

.option {
  box-sizing: border-box;
  font-size: 15px;
  cursor: pointer;
  display: block;
  padding: 10px 14px;
  text-align: left;
  color: #000000;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500; }

.option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.option:hover,
.option:focus {
  color: #FFFFFF;
  background-color: #FFC45E;
  border-radius: 3px 3px 0 0;
  outline: none; }

.option.is-selected {
  color: #FFC45E;
  border-radius: 3px 3px 0 0;
  background-color: rgba(255, 196, 94, 0.05); }

.noresults {
  box-sizing: border-box;
  cursor: default;
  display: block;
  padding: 8px 10px; }

.price {
  font-size: 15px;
  opacity: 0.5;
  float: right; }

.error .control {
  border-color: #fb4b4b !important; }

.error-message {
  color: #fb4b4b; }
