.wrapper {
  display: inline-block; }

.wrapper:hover .checkbox {
  border-color: currentColor; }

.wrapper > label {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #55C5AD; }

.wrapper > input {
  display: none; }

:global(.rtl) .wrapper > label {
  direction: rtl; }

.checkbox {
  position: relative;
  width: 18px;
  min-width: 16px;
  height: 18px;
  min-height: 16px;
  border: 2px solid;
  border-radius: 2px;
  box-sizing: border-box; }

.checkbox > .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  color: currentColor; }

.indeterminate {
  width: 8px;
  height: 2px; }

.checkbox > .inner > svg {
  width: 12px;
  height: 12px; }

.checkbox > .inner > svg > path {
  fill: currentColor; }

:global(.ltr) .children,
.children {
  padding-left: 12px;
  font-size: 18px;
  line-height: 16px;
  color: #333333; }

:global(.rtl) .children {
  padding-right: 12px; }

/* Checked State */
.wrapper.checked .checkbox {
  /*background-color: #fff;*/ }

.wrapper.checked .checkbox > .inner {
  opacity: 1; }

/* Unchecked State */
.wrapper.unchecked .checkbox {
  /*background-color: #fff;*/ }

.wrapper.unchecked .checkbox > .inner {
  opacity: 0; }

.wrapper.unchecked:hover .checkbox,
.wrapper.unchecked.hover .checkbox {
  /*background-color: #fff;*/ }

.wrapper.unchecked:active .checkbox,
.wrapper.unchecked.active .checkbox {
  /*background-color: #fff;*/ }

.wrapper.unchecked:active .checkbox > .inner,
.wrapper.unchecked.active .checkbox > .inner {
  opacity: 1; }

/* Dsiabled State */
.wrapper.disabled .checkbox,
.wrapper.disabled:hover .checkbox,
.wrapper.disabled.hover .checkbox,
.wrapper.disabled.active .checkbox,
.wrapper.disabled:active .checkbox {
  border-color: #929292;
  background-color: #929292; }

.wrapper.disabled.checked .checkbox,
.wrapper.disabled.checked:hover .checkbox,
.wrapper.disabled.checked.hover .checkbox,
.wrapper.disabled.checked.active .checkbox,
.wrapper.disabled.checked:active .checkbox {
  background-color: #929292; }

.error {
  color: #fb4b4b; }
  .error label, .error .children {
    color: #fb4b4b; }
