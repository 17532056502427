.header-background {
  background-color: #FFC45E;
  margin-right: -16px;
  margin-left: -16px; }

.order-number {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 35px; }

.continue-shopping-btn {
  margin: 50px 0; }

@media screen and (max-width: 768px) {
  .order-number {
    font-size: 20px;
    margin-bottom: 16px; } }
